import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      superadmin: 'superadmin',
      admin: 'admin',
      customer: 'customer',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static generateSuperAdminObj(currentTenant, tenantUser?) {
    const tenant = {
      ...tenantUser,
      status: 'active',
      tenant: currentTenant,
      roles: [this.values.superadmin],
    };
    return tenant;
  }
}

export default Roles;
