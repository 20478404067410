import React, { FC, useContext, useEffect, useState } from 'react';
import './card.scss';
import { ControlOutlined } from '@ant-design/icons';
import actions from 'src/modules/sheet/list/sheetListActions';
import { Card } from '../../../../base/card/card';
import { Col } from '../../../../base/layout/col/col';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { InputSelect } from '../../../../base/froms/inputSelect/inputSelect';
import ProductService from '../../../../../modules/product/productService';
import leadEnumerators from '../../../../../modules/lead/leadEnumerators';
import { i18n } from '../../../../../i18n';

import _ from 'lodash';
import { set as setOject } from 'lodash';
import CollapsableCard from 'src/components/base/collapsable/collapsable';
import { Button } from '../../../../base/button/button';
import SheetFormModal from 'src/view/sheet/form/SheetFormModal';
import { CUSTOMER_TYPE, SHEET_DETAIL_TYPE } from 'src/modules/common/constants';
import { useDispatch } from 'react-redux';
import { DETAIL_TYPE, SheetModalContext } from 'src/context/selectSheetModalContext';
import { DrawerContext } from 'src/context/drawerContext';

interface RequestDetailsCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const RequestDetailsCard: FC<RequestDetailsCardProps> = ({ record, reset, update }) => {
  const [productExtraFields, setProductExtraFields]: any = useState([]);
  const { setData, data } = useContext(DrawerContext);

  const [product, setProduct] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const { doOpenSheetSelectModal } = useContext(SheetModalContext);

  useEffect(() => {
    setProductExtraFields(record.productExtraFields || []);
  }, [record]);

  useEffect(() => {
    productList().then((res) => {
      setProduct(res);
    });
    setSource(leadEnumerators.source);
  }, [record]);

  const change = (key, date) => {
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  const extraFieldChange = (key, value) => {
    if (key !== undefined) {
      if (value !== undefined) {
        setOject(productExtraFields, key, value);
        update('productExtraFields', productExtraFields);
      }
    }
  };

  const productList = async () => {
    const tmp = await ProductService.list('', '', 100, 0);
    return tmp.rows;
  };

  const [modalVisible, setModalVisible] = useState(false);

  const doCloseModal = () => {
    setModalVisible(false);
  };
  const dispatch = useDispatch();

  const doOpenModal = () => {
    // setRecord({})
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const productExtraFields = data.productExtraFields ?? [];
    setData({ ...data, productExtraFields: [...productExtraFields, { sheet: record, value: '' }] });
    doCloseModal();
    dispatch(actions.doFetch());
  };

  return (
    <CollapsableCard icon={<ControlOutlined />} title={'Details zur Anfrage'} panelKey="lead-details-card">
      <Row>
        <Col>
          <InputSelect
            label={'Produkt'}
            dbField="product"
            value={_.hasIn(record.product, 'id') ? record.product.id : '-'}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {!_.hasIn(record.product, 'id') ? <option selected></option> : ''}

            {product.map((data: any, index: number) => {
              if (record.product?.name) {
                return record.product?.name === data.name ? (
                  <option key={index} value={data.id} selected>
                    {data.name}
                  </option>
                ) : (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                );
              } else {
                return (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                );
              }
            })}
          </InputSelect>
        </Col>
        <Col>
          <InputText
            label={'Entscheider'}
            dbField="secondDecisionMaker"
            value={record.secondDecisionMaker}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Auftragsvolumen'}
            dbField="budget"
            value={record.budget}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="currency"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Quelle'}
            dbField="source"
            value={record.source !== undefined ? record.source : ''}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {source.map((value: string, index: number) => {
              return record.source === value ? (
                <option key={index} value={value} selected>
                  {i18n(`entities.lead.enumerators.source.${value}`)}
                </option>
              ) : (
                <option key={index} value={value}>
                  {i18n(`entities.lead.enumerators.source.${value}`)}
                </option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
      <Row>
        {productExtraFields.map((item, index) => {
          if (item.sheet && item.sheet.keyName) {
            return (
              <Col>
                <InputText
                  label={item.sheet.keyName}
                  dbField={`${index}.value`}
                  value={item.value}
                  placeholder={''}
                  change={extraFieldChange}
                  reset={reset}
                  validateType="text"
                />
              </Col>
            );
          }
          return <></>;
        })}
      </Row>
      <div>
        <Button type="outline" onClick={doOpenModal}>
          Eigenschaft hinzufügen
        </Button>
        <Button type="outline" onClick={() => doOpenSheetSelectModal(DETAIL_TYPE.PRODUCT)}>
          Bestehende Eigenschaft hinzufügen
        </Button>
      </div>
      <SheetFormModal
        visible={modalVisible}
        onCancel={doCloseModal}
        onSuccess={doCreateSuccess}
        record={{ type: CUSTOMER_TYPE.LEAD, detailType: SHEET_DETAIL_TYPE.PRODUCT }}
        isTypedisabled
      />
    </CollapsableCard>
  );
};
