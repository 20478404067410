import React, { FC, useContext, useEffect, useState } from 'react';
import './card.scss';
import { ContactsOutlined, PhoneFilled } from '@ant-design/icons';
import actions from 'src/modules/sheet/list/sheetListActions';
import { Card } from '../../../../base/card/card';
import { InputText } from '../../../../base/froms/inputText/inputText';
import { Row } from '../../../../base/layout/row/row';
import { Col } from '../../../../base/layout/col/col';
import { InputSelect } from 'src/components/base/froms/inputSelect/inputSelect';
import { Button } from '../../../../base/button/button';
import CollapsableCard from 'src/components/base/collapsable/collapsable';
import { Select } from 'antd';
import SheetFormModal from 'src/view/sheet/form/SheetFormModal';
import { set as setOject } from 'lodash';
import { CUSTOMER_TYPE, SHEET_DETAIL_TYPE } from 'src/modules/common/constants';
import { useDispatch } from 'react-redux';
import { DrawerContext } from 'src/context/drawerContext';
import { DETAIL_TYPE, SheetModalContext } from 'src/context/selectSheetModalContext';

interface CustomerCardProps {
  record: any;
  reset: boolean;
  update: (key: string, value: string) => void;
}

export const CustomerCard: FC<CustomerCardProps> = ({ record, reset, update }) => {
  // const [country, setCountry] = useState<any>();
  const [customerExtraFields, setCustomerExtraFields]: any = useState([]);
  const { doOpenSheetSelectModal } = useContext(SheetModalContext);
  const { setData, data } = useContext(DrawerContext);

  const countryList = [
    { value: 'lu', label: 'Luxemburg' },
    { value: 'at', label: 'Österreich' },
    { value: 'ch', label: 'Schweiz' },
    { value: 'nl', label: 'Niederlande' },
    { value: 'fr', label: 'Frankreich' },
    { value: 'de', label: 'Deutschland' },
    { value: 'be', label: 'Belgien' },
    { value: 'it', label: 'Italien' },
    { value: 'pl', label: 'Polen' },
    { value: 'es', label: 'Spanien' },
  ];

  useEffect(() => {
    setCustomerExtraFields(record.customerExtraFields || []);
  }, [record]);

  const change = (key, date) => {
    if (key !== undefined) {
      if (date !== undefined) {
        update(key, date);
      }
    }
  };

  const dispatch = useDispatch();

  const extraFieldChange = (key, value) => {
    if (key !== undefined) {
      if (value !== undefined) {
        setOject(customerExtraFields, key, value);
        update('customerExtraFields', customerExtraFields);
      }
    }
  };

  const [modalVisible, setModalVisible] = useState(false);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    // setRecord({})
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const customerExtraFields = data.customerExtraFields ?? [];
    setData({ ...data, customerExtraFields: [...customerExtraFields, { sheet: record, value: '' }] });
    doCloseModal();
    dispatch(actions.doFetch());
  };

  return (
    <CollapsableCard icon={<ContactsOutlined />} title={'Stammdaten des Kunden'} panelKey="lead-customer-card">
      <Row>
        <Col>
          <InputText
            label={'Vorname'}
            dbField="firstName"
            value={record.firstName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputText
            label={'Nachname'}
            dbField="lastName"
            value={record.lastName}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Adresse'}
            dbField="street"
            value={record.street}
            placeholder={''}
            change={change}
            reset={reset}
            required={false}
            validateType="address"
          />
        </Col>
        <Col>
          <InputText
            label={'PLZ'}
            dbField="postalCode"
            value={record.postalCode}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="postCode"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Stadt'}
            dbField="city"
            value={record.city}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="text"
          />
        </Col>
        <Col>
          <InputSelect
            label={'Land'}
            dbField="country"
            value={record.country}
            placeholder={''}
            change={change}
            reset={reset}
          >
            {countryList.map((data: any, index: number) => {
              return record.country === data.value ? (
                <Select.Option key={index} value={data.value} selected>
                  {data.label}
                </Select.Option>
              ) : (
                <Select.Option key={index} value={data.value}>
                  {data.label}
                </Select.Option>
              );
            })}
          </InputSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label={'Telefon'}
            dbField="phone"
            value={record.phone}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="phone"
          />
        </Col>
        <div className="visibleOnMobile">
          <Col>
            <a href={'tel:' + record.phone}>
              <Button type="primary">
                <PhoneFilled />
                <span> Jetzt anrufen</span>
              </Button>
            </a>
          </Col>
        </div>
        <Col>
          <InputText
            label={'E-Mail'}
            dbField="email"
            value={record.email}
            placeholder={''}
            change={change}
            reset={reset}
            validateType="email"
          />
        </Col>
      </Row>
      <Row>
        {customerExtraFields.map((item, index) => {
          if (item.sheet && item.sheet.keyName) {
            return (
              <Col>
                <InputText
                  label={item.sheet.keyName}
                  dbField={`${index}.value`}
                  value={item.value}
                  placeholder={''}
                  change={extraFieldChange}
                  reset={reset}
                  validateType="text"
                />
              </Col>
            );
          }
          return <></>;
        })}
      </Row>
      <div>
        <Button type="outline" onClick={doOpenModal}>
          Eigenschaft hinzufügen
        </Button>
        <Button type="outline" onClick={() => doOpenSheetSelectModal(DETAIL_TYPE.CUSTOMER)}>
          Bestehende Eigenschaft hinzufügen
        </Button>
      </div>

      <SheetFormModal
        visible={modalVisible}
        onCancel={doCloseModal}
        onSuccess={doCreateSuccess}
        record={{ type: CUSTOMER_TYPE.LEAD, detailType: SHEET_DETAIL_TYPE.CUSTOMER }}
        isTypedisabled
      />
    </CollapsableCard>
  );
};
